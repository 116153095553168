@import "variables";

@import "~bootstrap/scss/bootstrap";

@import "./layout/sidebar";
@import "./layout/container";


.plainLink{
    text-decoration: none;
}

.smallMenu{
    font-size: .85em;
    display: inline-block;
}